import React from "react"
import { useStateIfMounted } from "use-state-if-mounted"
import '../layout.css'
import { useLocation } from "@reach/router"
import { MainPageJson, PathJson, HowToSgJson } from "../location"
import { NavigationBar } from "../NavigationBar/parent"
import { HowToComponents } from "./HowToSgComponents"
import Footer from "../FooterComponents/Footer"
import Seo from "../SEOComponents/Seo"

function HowToSg() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const HowToContent = HowToSgJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")

  return (
    <div className="maxwidth">
      <Seo title="how-to" />
      <NavigationBar />
      <HowToComponents
        json={HowToContent}
        isEnglish={isEnglish}
        location={location}
      />
    </div>
  )
}

export default HowToSg
