import { get } from "lodash"
import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import { CustomAccordion } from "../HomeComponents/Accordion"
import { AdvantagesForHowToId } from "../HomeComponents/Advantages"

export const HowToComponents = ({ json, isEnglish, location }) => {
  const [defaultKey, setDefaultKey] = useState("0")
  const getHash = get(location, "hash", "")
  const { Verified, TopUp, Transfer, Bills, Pros } = json
  const StepData = [
    {
      eventId: "0",
      idName: "pros",
      name: Pros.Title,
      customBody: <AdvantagesForHowToId props={Pros} />,
    },
    {
      eventId: "1",
      idName: "topup",
      name: TopUp.Title,
      video: "lgchGX96XvY",
      videoLabel: isEnglish
        ? `Check out this video to know how to top up your e-wallet`
        : `Lihat video cara top up e-wallet`,
      bodyData: [
        {
          eventId: "0",
          name: TopUp.Point1,
          content: TopUp.Desc1,
        },
        {
          eventId: "1",
          name: TopUp.Point2,
          content: TopUp.Desc2,
        },
        {
          eventId: "2",
          name: TopUp.Point3,
          content: TopUp.Desc3,
        },
        {
          eventId: "3",
          name: TopUp.Point4,
          content: TopUp.Desc4,
        },
        {
          eventId: "4",
          name: TopUp.Point5,
          content: TopUp.Desc5,
        },
        {
          eventId: "5",
          name: TopUp.Point6,
          content: TopUp.Desc6,
        },
      ],
    },
    {
      eventId: "2",
      idName: "transfer",
      name: Transfer.Title,
      video: "eh-US0EEtBI",
      videoLabel: isEnglish
        ? `Check out this video to find out how to transfer from Singapore`
        : `Lihat video cara transfer dari Singapura`,
      bodyData: [
        {
          eventId: "0",
          name: Transfer.Point1,
          content: Transfer.Desc1,
        },
        {
          eventId: "1",
          name: Transfer.Point2,
          content: Transfer.Desc2,
        },
        {
          eventId: "2",
          name: Transfer.Point3,
          content: Transfer.Desc3,
        },
        {
          eventId: "3",
          name: Transfer.Point4,
          content: Transfer.Desc4,
        },
        {
          eventId: "4",
          name: Transfer.Point5,
          content: Transfer.Desc5,
        },
        {
          eventId: "5",
          name: Transfer.Point6,
          content: Transfer.Desc6,
        },
        {
          eventId: "6",
          name: Transfer.Point7,
          content: Transfer.Desc7,
        },
        {
          eventId: "7",
          name: Transfer.Point8,
          content: Transfer.Desc8,
        },
        {
          eventId: "8",
          name: Transfer.Point9,
          content: Transfer.Desc9,
        },
      ],
    },
    {
      eventId: "3",
      idName: "bills",
      name: Bills.Title,
      video: "KsHwcVvV7xA",
      videoLabel: isEnglish
        ? `Check out this video to know how to pay bills from Singapore`
        : `Lihat video cara bayar tagihan dari Singapura`,
      bodyData: [
        {
          eventId: "0",
          name: Bills.Point1,
          content: Bills.Desc1,
        },
        {
          eventId: "1",
          name: Bills.Point2,
          content: Bills.Desc2,
        },
        {
          eventId: "2",
          name: Bills.Point3,
          content: Bills.Desc3,
        },
        {
          eventId: "3",
          name: Bills.Point4,
          content: Bills.Desc4,
        },
        {
          eventId: "4",
          name: Bills.Point5,
          content: Bills.Desc5,
        },
        {
          eventId: "5",
          name: Bills.Point6,
          content: Bills.Desc6,
        },
        {
          eventId: "6",
          name: Bills.Point7,
          content: Bills.Desc7,
        },
      ],
    },
    {
      eventId: "4",
      idName: "verified",
      name: Verified.Title,
      video: "Jaozvo4u22U",
      videoLabel: isEnglish
        ? `Check out this video to know how to verify your account`
        : `Lihat video cara verifikasi akun`,
      bodyData: [
        {
          eventId: "0",
          name: Verified.Point1,
          content: Verified.Desc1,
        },
        {
          eventId: "1",
          name: Verified.Point2,
          content: Verified.Desc2,
        },
        {
          eventId: "2",
          name: Verified.Point3,
          content: Verified.Desc3,
        },
        {
          eventId: "3",
          name: Verified.Point4,
          content: Verified.Desc4,
        },
        {
          eventId: "4",
          name: Verified.Point5,
          content: Verified.Desc5,
        },
        {
          eventId: "5",
          name: Verified.Point6,
          content: Verified.Desc6,
        },
        {
          eventId: "6",
          name: Verified.Point7,
          content: Verified.Desc7,
        },
        {
          eventId: "7",
          name: Verified.Point8,
          content: Verified.Desc8,
        },
        {
          eventId: "8",
          name: Verified.Point9,
          content: Verified.Desc9,
        },
      ],
    },
  ]

  useEffect(() => {
    if (getHash === "#pros") {
      setDefaultKey("0")
    } else if (getHash === "#topup") {
      setDefaultKey("1")
    } else if (getHash === "#transfer") {
      setDefaultKey("2")
    } else if (getHash === "#bills") {
      setDefaultKey("3")
    } else if (getHash === "#verified") {
      setDefaultKey("4")
    }
  }, [getHash])

  return (
    <>
      <Container style={{ paddingTop: 120 }}>
        <CustomAccordion data={StepData} isHowTo defaultKey={defaultKey} />
      </Container>
    </>
  )
}
