import React, { useState, Fragment, useEffect } from "react"
import styled from "styled-components"
import { Container, Accordion, Button, Card } from "react-bootstrap"

export const DotAccordion = ({
  data,
  sendToParent,
  isCountries,
  isHowTo,
  idName,
  isSg,
  isHowToUse,
  mbottom
}) => {
  const [defId, setDefId] = useState("0")

  const Toggle = e => {
    setDefId(e)
    if (isCountries || isHowToUse) {
      sendToParent(e)
    }
    if (isSg) {
      sendToParent([idName, e])
    }
  }

  return (
    <>
      <CustomAccordion defaultActiveKey="0" mbottom={mbottom}>
        {data.map(({ eventId, name, content }, id) => {
          const NumDefId = Number(defId)
          const NumEventId = Number(eventId)
          const isBefore = NumEventId <= NumDefId
          const isLast = NumEventId === data.length - 1
          const isSame = NumDefId === NumEventId

          return (
            <Fragment key={id}>
              <CustomCard>
                <SpanDotted hg={isLast} />
                <SpanBig bc={isBefore} />
                <CustomCardHeader>
                  <Accordion.Toggle
                    as={CustomButton}
                    variant="link"
                    eventKey={eventId}
                    onClick={() => Toggle(eventId)}
                  >
                    <CustomText fweight={isSame}>{name}</CustomText>
                  </Accordion.Toggle>
                </CustomCardHeader>
                <Accordion.Collapse eventKey={eventId}>
                  <div>
                    <CustomCardBody
                      w576={isHowTo && "100%"}
                      alignres={isHowTo && isSg && "left"}
                    >
                      <CustomTextBody>{content}</CustomTextBody>
                    </CustomCardBody>
                  </div>
                </Accordion.Collapse>

                <DummyDiv/>
              </CustomCard>
            </Fragment>
          )
        })}
      </CustomAccordion>
    </>
  )
}

const CustomAccordion = styled(Accordion)`
  margin-bottom: ${p => p.mbottom || "0px"};
`

const SpanDotted = styled.span`
  position: absolute;
  border-left: 5px dotted #ffd27c;
  height: ${p => (p.hg ? "0%" : "150%")};
  margin-top: 1.5%;
  margin-left: -10px;
`

const DummyDiv = styled.div`
  min-height: 1.5rem;
  background-color: transparent;
`

const SpanBig = styled.span`
  height: 15px;
  width: 15px;
  background-color: ${p => (p.bc ? "#ffd27c" : "#FFF")};
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 3px;
  border: 3px solid #ffd27c;
`

const CustomCardHeader = styled(Card.Header)`
  background-color: transparent;
  border: 0px;
  padding: 0px 20px;
`

const CustomText = styled.h4`
  color: #da649f;
  font-size: 20px;
  font-family: "Typefez-med";
  text-align: left;
  margin-bottom: 0.5rem;
  font-weight: ${p => (p.fweight ? "600" : "400")};
  @media (max-width: 992px) {
    text-align: left;
  }
  @media (max-width: 576px) {
    font-size: 17px;
  }
`

const CustomTextBody = styled.p`
  color: #333334;
  font-size: 16px;
  font-family: "Typefez-reg";
  margin-bottom: 0;
  @media (max-width: 576px) {
    font-size: 13px;
  }
`

const CustomCard = styled(Card)`
  border: 0px;
  padding-left: 15px;
  background-color: transparent;
`

const CustomCardBody = styled(Card.Body)`
  font-family: "Typefez-reg";
  width: ${p => p.width || "80%"};
  text-align: left;
  margin-left: 5px;
  margin-top: -0.5%;
  height: auto;
  padding: 10px 20px 0px 20px;
  @media (max-width: 576px) {
    width: ${p => p.w576 || "90%"};
    padding: ${p => p.respad};
    text-align: ${p => p.alignres};
  }
`

const CustomButton = styled(Button)`
  padding: 0px;
  background-color: transparent;
  border: none;
  :hover,
  :focus,
  :active {
    outline: none !important;
    box-shadow: none !important;
    text-decoration: none !important;
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
  }
`
