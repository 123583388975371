import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Fade } from "react-reveal"
import { Row, Col, Container } from "react-bootstrap"
import styled from "styled-components"
import { IdComponent } from "../Reusable/id-components"

export const Advantages = ({ props }) => {
  const data = useStaticQuery(graphql`
    query {
      adv: file(relativePath: { eq: "advantages-img.png" }) {
        childImageSharp {
          fluid(quality: 30) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const advImg = data.adv.childImageSharp.fluid

  const {
    Title,
    Point1,
    Desc1,
    Point2,
    Desc2,
    Point3,
    Desc3,
    Point4,
    Desc4,
    Point5,
    Desc5,
  } = props

  const datas = [
    {
      id: 1,
      point: Point1,
      desc: Desc1,
    },
    {
      id: 2,
      point: Point2,
      desc: Desc2,
    },
    {
      id: 3,
      point: Point3,
      desc: Desc3,
    },
    {
      id: 4,
      point: Point4,
      desc: Desc4,
    },
    {
      id: 5,
      point: Point5,
      desc: Desc5,
    },
  ]
  return (
    <>
      <IdComponent title="why-choose" />
      <AdvantagesDiv>
        <Container>
          <AdvantagesTitle>
            {Title}
            <span style={{ color: "#FFD27C" }}>?</span>
          </AdvantagesTitle>
          <Row>
            <Col lg={8}>
              <Fade left>
                {datas.map(data => (
                  <Row key={data.id} className="my-2">
                    <AdvTextCol sm={3}>
                      <AdvantagesPoint>{data.point} </AdvantagesPoint>
                      <CircleWhite />
                    </AdvTextCol>
                    <AdvDescCol sm={8}>
                      <AdvantagesDesc>{data.desc}</AdvantagesDesc>
                    </AdvDescCol>
                  </Row>
                ))}
              </Fade>
            </Col>
            <Col lg={4}>
              <ImgAdv fluid={advImg} fadeIn alt="advantages" />
            </Col>
          </Row>
        </Container>
      </AdvantagesDiv>
    </>
  )
}

export const AdvantagesForHowToId = ({ props }) => {
  const {
    Point1,
    Desc1,
    Point2,
    Desc2,
    Point3,
    Desc3,
    Point4,
    Desc4,
    Point5,
    Desc5,
  } = props

  const datas = [
    {
      id: 1,
      point: Point1,
      desc: Desc1,
    },
    {
      id: 2,
      point: Point2,
      desc: Desc2,
    },
    {
      id: 3,
      point: Point3,
      desc: Desc3,
    },
    {
      id: 4,
      point: Point4,
      desc: Desc4,
    },
    {
      id: 5,
      point: Point5,
      desc: Desc5,
    },
  ]
  return (
    <div style={{ position: "relative" }}>
      {datas?.map(data => (
        <Row key={data.id} className="my-1">
          <AdvTextCol md={3}>
            <AdvantagesPoint>{data.point} </AdvantagesPoint>
            <CircleYellow />
          </AdvTextCol>
          <AdvDescCol md={8}>
            <AdvantagesDesc>{data.desc}</AdvantagesDesc>
          </AdvDescCol>
        </Row>
      ))}
    </div>
  )
}

const CircleYellow = styled.p`
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-color: #102C57;
  margin-left: -25px;
  margin-bottom: 5px;
  font-family: "Typefez-reg";
`

const AdvantagesDiv = styled.div`
  margin-top: 100px;
  margin-bottom: 50px;
  @media (max-width: 576px) {
    margin-top: 50px;
  }
`

const AdvTextCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: center;
  }
`

const AdvDescCol = styled(Col)`
  @media (max-width: 576px) {
    padding-left: 13%;
    padding-right: 13%;
  }
`

const AdvantagesTitle = styled.h2`
  font-family: "Typefez-med";
  font-size: 36px;
  color: ${props => props.color || "#48a9b5"};
  margin-bottom: 2rem;
  @media (max-width: 576px) {
    text-align: center;
  }
`

const AdvantagesPoint = styled.h3`
  font-family: "Typefez-med";
  color: #DB64A0;
  font-size: 20px;
  position: relative;
  z-index: 1;
`

const CircleWhite = styled.p`
  min-width: 40px;
  min-height: 40px;
  margin-left: -25px;
  border-radius: 50%;
  background-color: #ffefd2;
  z-index: -1;
`

const AdvantagesDesc = styled.p`
  font-family: "GothamBook";
  font-size: 13px;
  color: #828282;
  @media (max-width: 576px) {
    text-align: justify;
  }
`

const ImgAdv = styled(Img)`
  z-index: -1;
  position: absolute;
  right: -20%;
  top: -20%;
  min-height: 350px;
  min-width: 350px;
  @media (max-width: 1024px) {
    display: none;
  }
`
